import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'

@Component({
  selector: 'tc-shell-setup',
  templateUrl: './shell-setup.component.html',
  styleUrls: ['./shell-setup.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellSetupComponent implements OnInit {
  public screenWidth: number

  constructor() {}
  ngOnInit(): void {
    this.screenWidth = window.innerWidth
    document.body.style.backgroundImage = null
    document.body.style.backgroundSize = null
  }

  getLogo(): string {
    if (this.screenWidth < 767) {
      return 'assets/icons/txc-icon-light.png'
    } else {
      return 'assets/icons/taxcloud-logo-text-light.svg'
    }
  }
}
