import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, Observable, combineLatest, fromEvent } from 'rxjs'
import { map, filter, tap, delay, first, withLatestFrom, debounceTime } from 'rxjs/operators'
import * as fromAccount from 'src/app/account/account.state'
import * as fromAccountSelectors from 'src/app/account/selectors/account.selectors'
import { AccountStateEnum } from 'src/app/enums/account.enums'
import * as fromNotificationActions from 'src/app/notification/actions/notification.actions'
import { INotification } from 'src/app/notification/models/inotification.model'
import { State as NotificationState } from 'src/app/notification/notification.state'
import * as fromNotificationSelectors from 'src/app/notification/selectors/notification.selectors'
import { SubSink } from 'subsink'
import { State as TransactionState } from 'src/app/transaction/transaction.store'
import { LoginEnum, LoginTypeEnum } from 'src/app/enums/login.enum'
import * as fromUserActions from 'src/app/user/actions/login.actions'
import * as fromUserSelector from 'src/app/user/selectors/user.selectors'
import * as fromUser from 'src/app/user/user.state'
import * as fromBilling from 'src/app/manage-account/manage-account.state'
import { InitNotifications } from 'src/app/notification/actions/notification.actions'
import * as fromTransactionActions from 'src/app/transaction/actions/transaction.actions'
import { State as AppStatusState } from 'src/app/app-status/app-status.state'
import {
  selectIsEmbedded,
  selectIsInstallable,
} from 'src/app/app-status/selectors/app-status.selectors'
import { selectLoginType } from 'src/app/user/selectors/user.selectors'
import { State as UsStateState } from 'src/app/us-states/us-states.store'
import { InitStatesGoShell } from 'src/app/us-states/actions/us-states.actions'
import * as appStatusActions from 'src/app/app-status/actions/app-status.actions'
import { AppStatusService } from 'src/app/app-status/services/app-status.service'
import { AttestationService } from 'src/app/attestation/services/attestation.service'
import * as fromRouterSelectors from 'src/app/root/selectors/root.selectors'
import * as fromRouter from '@ngrx/router-store'
import { IRouterState } from 'src/app/root/models/irouterstate'
import { Title } from '@angular/platform-browser'
import { IConversionStatus } from 'src/app/account/models/iaccount'
import { TosService } from 'src/app/terms-of-service/services/terms-of-service.service'
import { IUser } from 'src/app/user/models/iuser'
import { AlertTypeEnum } from 'src/app/enums/alert.enums'
import { environment } from 'src/environments/environment'
import { MatSidenav } from '@angular/material/sidenav'
import * as billingActions from '../../../../manage-account/actions/billing.action'
import * as billingSelector from 'src/app/manage-account/selectors/billing.selector'
import { PlanSubscription } from 'src/app/manage-account/models/billing.model'
import { HelpScoutBeaconService } from 'src/app/root/services/helpscout.service'
import { State as SetupStore } from 'src/app/setup/setup.state'
import * as setupActions from 'src/app/setup/actions/setup.actions'
import { hasCurrentActiveSubscriptionStatus } from 'src/app/utilities/subscription.utilities'
import { ILocation } from 'src/app/location/models/ilocation'
import * as fromLocationSelectors from 'src/app/location/selectors/location-selector'
import * as fromLocation from 'src/app/location/location.store'
import { NotificationService } from 'src/app/notification/services/notification.service'
import { LayoutService } from './layout.service'

interface ILayout {
  mode: string
  disableClose: boolean
  opened: boolean
}

@Component({
  templateUrl: './shell-go.component.html',
  styleUrls: ['./shell-go.component.sass'],
})
export class ShellGoComponent implements OnInit, OnDestroy {
  public accountExpandPanelOpen = false
  public settingsExpandPanelOpen = false
  public internalExpandPanelOpen = false
  public invoicesExpandPanelOpen = false
  public transactionsExpandPanelOpen = false
  public developerPanelOpen = false
  public publicSiteUrl = environment.publicSiteUrl
  public msgCenter = environment.features['message-center']
  private subs = new SubSink()

  public isMobileWidth$ = new BehaviorSubject<boolean>(this.getIsMobileWidth())
  public toolbarHeight$ = new BehaviorSubject<string>('72px')
  public routerTopMargin$ = new BehaviorSubject<string>('64px')

  public loggedInStatus: LoginEnum
  layout$: Observable<ILayout>
  public showFinanceTools$ = new BehaviorSubject<boolean>(false)
  public subscriptionAvailable$ = new BehaviorSubject<boolean>(false)
  locations$: Observable<ILocation[]> = this.locationStore.select(fromLocationSelectors.selectAll)
  isInitialLocationDataLoaded$: Observable<boolean> = this.locationStore.select(
    fromLocationSelectors.initialLocationDataLoaded
  )

  @ViewChild('messageSideBar') messageSideBar: MatSidenav

  constructor(
    private layoutService: LayoutService,
    private locationStore: Store<fromLocation.State>,
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: Store<fromRouter.RouterReducerState<IRouterState>>,
    private userStore: Store<fromUser.State>,
    private accountStore: Store<fromAccount.State>,
    private notificationStore: Store<NotificationState>,
    private transactionStore: Store<TransactionState>,
    private appStatusStore: Store<AppStatusState>,
    private usStateStore: Store<UsStateState>,
    private attestationService: AttestationService,
    private appStatusService: AppStatusService,
    private titleServce: Title,
    private tosService: TosService,
    private billingStore: Store<fromBilling.State>,
    private helpScoutService: HelpScoutBeaconService,
    private setupStore: Store<SetupStore>
  ) {
    // Init Help Scout
    this.helpScoutService.init()
  }

  public isLarge$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .pipe(map((breakpoint) => breakpoint.matches))

  public isEmbeddedApp$: Observable<boolean> = this.appStatusStore.select(selectIsEmbedded)

  public isInstallable$: Observable<boolean> = this.appStatusStore.select(selectIsInstallable)

  public isAutoAuth$: Observable<boolean> = this.userStore
    .select(selectLoginType)
    .pipe(map((loginType) => loginType === LoginTypeEnum.Auto))
  public showNotifications$: Observable<boolean> = this.accountStore.pipe(
    select(fromAccountSelectors.selectAccountStatus),
    map((state) => state !== AccountStateEnum.Prospective)
  )

  public closeDate$: Observable<string> = this.accountStore
    .select(fromAccountSelectors.selectCloseWindow)
    .pipe(
      map((close) => {
        if (!close) {
          return null
        }
        return close.end
      })
    )

  public numberOfMessages$: Observable<number> = this.notificationStore.pipe(
    select(fromNotificationSelectors.selectTotal)
  )

  public messages$: Observable<INotification[]> = this.notificationStore.pipe(
    select(fromNotificationSelectors.selectAll)
  )

  public blogAlert$: Observable<boolean> = this.messages$.pipe(
    map((msgs) => !!msgs.find((msg) => msg.type === AlertTypeEnum.BlogAlert))
  )

  public accountStatus$: Observable<AccountStateEnum> = this.accountStore.pipe(
    select(fromAccountSelectors.selectAccountStatus)
  )

  public hasLatestTos$: Observable<boolean> = this.userStore.pipe(
    select(fromUserSelector.selectHasLatestTermsOfService)
  )

  public isInternal$: Observable<boolean> = this.userStore.select(
    fromUserSelector.selectIsInternalUser
  )
  public isMasquerading$: Observable<boolean> = this.userStore.select(
    fromUserSelector.selectUserIsMasquerading
  )

  public status$: Observable<LoginEnum> = this.userStore.select(fromUserSelector.selectLoginStatus)

  public isLoggedIn$: Observable<boolean> = this.status$.pipe(
    map((status) => status === LoginEnum.LoggedIn)
  )

  public showLogoutButton$: Observable<boolean> = combineLatest([
    this.status$,
    this.isEmbeddedApp$,
  ]).pipe(
    map(([status, isEmbedded]) => {
      if (isEmbedded) {
        return false
      }
      return status !== LoginEnum.LoggedOut
    })
  )

  public firstInitial: Observable<string> = this.userStore.pipe(
    select(fromUserSelector.selectFullName),
    map((name) => (name ? name.substring(0, 1) : 'U'))
  )

  public fullName: Observable<string> = this.userStore.select(fromUserSelector.selectFullName)

  public authority: Observable<string> = this.userStore.select(
    fromUserSelector.selectUserAuthorityLabel
  )

  public needsPaAttestation$: Observable<boolean> = this.accountStore.select(
    fromAccountSelectors.selectNeedsPaAttestation
  )

  public businessName$: Observable<string> = this.accountStore.select(
    fromAccountSelectors.selectBusinessName
  )

  public merchantId$: Observable<number> = this.accountStore.select(
    fromAccountSelectors.selectMerchantId
  )

  public conversionStatus$: Observable<IConversionStatus> = this.accountStore.select(
    fromAccountSelectors.selectConversionStatus
  )

  public hasFiscalAuth$: Observable<boolean> = this.userStore.select(
    fromUserSelector.selectUserHasFiscalAuthority
  )

  public subscription$: Observable<PlanSubscription> = this.billingStore.select(
    billingSelector.selectSubscription
  )

  public subscriptionLoading$: Observable<boolean> = this.billingStore.select(
    billingSelector.selectSubscriptionLoading
  )

  public showUpgradeButton$: Observable<boolean> = combineLatest(
    [
      this.subscription$,
      this.subscriptionLoading$,
      this.route.select(fromRouterSelectors.selectRouteState),
    ],
    (subscription, subscriptionLoading, routeState) => {
      return (
        !subscription &&
        !subscriptionLoading &&
        window.location.pathname !== '/go/account/service-plan'
      )
    }
  )

  public showMustSubscribe$: Observable<boolean> = combineLatest([
    this.subscription$,
    this.subscriptionLoading$,
  ]).pipe(
    map(([subscription, subscriptionLoading]) => {
      const hasPlan =
        !!subscription && hasCurrentActiveSubscriptionStatus(subscription) && !subscriptionLoading

      return !hasPlan
    })
  )

  public isFreeTrialSubscription$: Observable<boolean> = this.billingStore.select(
    billingSelector.selectIsFreeTrialSubscription
  )

  public freeTrialRemainingDays$: Observable<number> = this.billingStore.select(
    billingSelector.selectFreeTrialRemainingDays
  )

  private user$: Observable<IUser> = this.userStore.pipe(
    select(fromUserSelector.selectAuthorizeUser)
  )

  getLogo(): string {
    if (this.isMobileWidth$.getValue()) {
      return 'assets/icons/txc-icon-light.png'
    } else {
      return 'assets/icons/taxcloud-logo-text-light.svg'
    }
  }

  ngOnInit(): void {
    this.updateSpacing()
    this.subs.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => {
          this.updateSpacing()
        })
    )

    this.subs.add(
      combineLatest([this.isMasquerading$, this.showMustSubscribe$]).subscribe(() => {
        this.updateSpacing()
      })
    )

    this.layout$ = this.layoutService.layout
    this.subs.add(
      this.subscription$.pipe(tap((sub) => this.subscriptionAvailable$.next(!!sub))).subscribe()
    )

    this.subs.add(
      this.route
        .select(fromRouterSelectors.selectBreadcrumbs)
        .pipe(
          delay(100),
          map((breadcrumbs) =>
            breadcrumbs
              .filter((crumb) => crumb.label.toLowerCase() !== 'go')
              .reduce((acc, curr) => `${acc} ${curr.label}`, '')
          ),
          filter((title) => !!title),
          tap((title) => {
            this.titleServce.setTitle(title)
          })
        )
        .subscribe()
    )

    this.subs.add(
      combineLatest([this.accountStatus$, this.isLoggedIn$])
        .pipe(
          filter(([accountStatus$, isLoggedIn]) => !!isLoggedIn),
          map(([accountStatus$, isLoggedIn]) => accountStatus$),
          tap((accountStatus$) => {
            if (
              accountStatus$ === AccountStateEnum.Prospective ||
              accountStatus$ === AccountStateEnum.Suspended
            ) {
              this.transactionStore.dispatch(
                new fromTransactionActions.UpdateFiltersShell({ filter: { isTest: true } })
              )
            } else if (accountStatus$ === AccountStateEnum.Converted) {
              // Removing this 03/26/2024 to improve performance. Will delete this once we know 100% there isn't unintended consequences.
              // this.transactionStore.dispatch(new fromTransactionActions.InitTransactions())
            }
          }),
          tap((_) => this.notificationStore.dispatch(new InitNotifications())),
          tap((_) => this.usStateStore.dispatch(new InitStatesGoShell())),
          filter((accountStatus) => !!accountStatus),
          tap((_) => {
            if (!this.subscriptionAvailable$.value) {
              this.billingStore.dispatch(
                new billingActions.InitSubscription({ suppress404Error: true })
              )
            }
          }),
          tap((_) => this.billingStore.dispatch(new billingActions.InitPaymentMethods())),
          tap((_) => this.setupStore.dispatch(new setupActions.InitIsOnboardingCompleted()))
        )
        .subscribe()
    )

    this.subs.add(
      this.needsPaAttestation$
        .pipe(
          filter((needsPa) => !!needsPa),
          tap((needsPa) => {
            if (needsPa && !document.cookie.includes('hideAddPaAttestationDialog=true')) {
              this.attestationService.openPaAttestationDialog()
              return
            }
            this.attestationService.closePaAttestationDialog()
          })
        )
        .subscribe()
    )
    this.subs.add(
      combineLatest([this.accountStatus$, this.isLoggedIn$, this.conversionStatus$])
        .pipe(
          filter(([accountStatus$, isLoggedIn, conversionStatus$]) => !!isLoggedIn),
          tap(([accountStatus$, isLoggedIn, conversionStatus$]) => {
            if (accountStatus$ === AccountStateEnum.Prospective) {
              const conversion =
                conversionStatus$.hasCompleteProfile &&
                conversionStatus$.hasCompleteTransaction &&
                conversionStatus$.hasPaymentMethod
              if (conversion) {
                this.appStatusService.openConversionStatusDialog()
                return
              }
              this.appStatusService.closeConversionStatusDialog()
            }
          })
        )
        .subscribe()
    )

    this.subs.add(
      combineLatest([this.isInternal$, this.isMasquerading$, this.accountStatus$, this.user$])
        .pipe(
          filter(([isInternal, isMasquerading, accountStatus, user]) => !!accountStatus),
          filter(([isInternal, isMasquerading, accountStatus, user]) => !!user),
          first(),
          tap(([isInternal, isMasquerading, accountStatus, user]) => {
            if (
              !user.hasLatesestTermsOfService &&
              accountStatus === AccountStateEnum.Converted &&
              !isInternal &&
              !isMasquerading
            ) {
              this.tosService.openNeedLatestTosDialog()
              return
            }
            this.tosService.closeNeedLatestTosDialog()
          })
        )
        .subscribe()
    )

    this.subs.add(
      this.isInitialLocationDataLoaded$
        .pipe(
          withLatestFrom(this.isLoggedIn$, this.locations$),
          tap(([isInitialLocationDataLoaded, isLoggedIn, locations]) => {
            const showWarning = isLoggedIn && locations.length < 1 && isInitialLocationDataLoaded
            if (showWarning) {
              this.notificationService.showWarning({
                title: 'Location Missing',
                description:
                  'Go to Settings -> Locations to add a location. Certain features are not accessible without a valid location',
                sticky: true,
              })
            }
          })
        )
        .subscribe()
    )
  }
  private updateSpacing() {
    this.isMobileWidth$.next(this.getIsMobileWidth())
    this.routerTopMargin$.next(this.getRouterTopMargin())
    this.toolbarHeight$.next(this.getToolbarHeight())
  }

  getIsMobileWidth(): boolean {
    return window.innerWidth < 767
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  public installApp(): void {
    this.appStatusStore.dispatch(new appStatusActions.InstallApp())
  }

  public toggle(): void {
    this.layoutService.toggle()
  }
  public toggleMenu(): void {
    this.layoutService.toggleMenu()
  }

  public close(): void {
    this.layoutService.close()
  }

  public lock(): void {
    this.userStore.dispatch(new fromUserActions.LockApplication())
    this.router.navigate(['/sign-in'])
  }

  public logout(): void {
    this.toggle()
    this.userStore.dispatch(new fromUserActions.Logout())
  }

  public goDevSupport(): void {
    this.toggle()
    const url = new URL(`${this.publicSiteUrl}/support?category=developer`)
    window.open(url.toString(), '_blank')
  }

  public goToServicePlan(): void {
    this.router.navigate(['/go/account/service-plan'])
  }

  public deleteNotice(id: string) {
    this.notificationStore.dispatch(new fromNotificationActions.DeleteNotification({ id }))
  }

  private calculateToolbarHeight(): number {
    const SINGLE_SECTION_HEIGHT = 72
    let height = SINGLE_SECTION_HEIGHT

    if (this.isMobileWidth$.getValue() === true) {
      const subscription = combineLatest([this.isMasquerading$, this.showMustSubscribe$]).subscribe(
        ([isMasquerading, showMustSubscribe]) => {
          if (isMasquerading) {
            height += SINGLE_SECTION_HEIGHT
          }
          if (showMustSubscribe) {
            height += SINGLE_SECTION_HEIGHT
          }
        }
      )
      this.subs.add(subscription)
    }
    return height
  }

  public getRouterTopMargin(): string {
    let height = this.calculateToolbarHeight() - 8
    return `${height}px`
  }

  public getToolbarHeight(): string {
    let topMargin = this.calculateToolbarHeight()
    return `${topMargin}px`
  }
}
