import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, throwError } from 'rxjs'
import { catchError, first, tap } from 'rxjs/operators'
import { NotificationService } from 'src/app/notification/services/notification.service'
import * as fromUserActions from 'src/app/user/actions/login.actions'
import * as fromSelector from 'src/app/user/selectors/user.selectors'
import { AuthService } from 'src/app/user/services/auth.service'
import * as fromUser from 'src/app/user/user.state'
import { environment } from 'src/environments/environment'
import { GeminiService } from './services/gemini.service'

export const SUPPRESS_ON_404_ERROR_NOTIFY = new HttpContextToken<boolean>(() => false)

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private notify: NotificationService,
    private authService: AuthService,
    private userStore: Store<fromUser.State>,
    private router: Router,
    private geminiService: GeminiService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let apiReq: HttpRequest<any>

    // Gather any http context
    const suppressOnErrorNotify = req.context.get(SUPPRESS_ON_404_ERROR_NOTIFY)

    // get from store rather than local because embedded app may not save local
    this.userStore
      .select(fromSelector.selectAuthToken)
      .pipe(
        first(),
        tap((token) => {
          apiReq = this.determineRoute(req, token)
        })
      )
      .subscribe()

    // TODO consider adding a retry policy for some endpoints
    // login should not retry (inconstent error messages)

    return next.handle(apiReq).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.userStore.dispatch(new fromUserActions.LockApplication())
          this.router.navigate(['/sign-in'])
          return
        }
        if (environment.logging.appShowError && !suppressOnErrorNotify && error.status === 404) {
          this.notify.showError({ title: error.message || 'Something broke' })
        }
        return throwError(error)
      })
    )
  }

  private determineRoute(req: HttpRequest<any>, token: string): HttpRequest<any> {
    let apiReq: HttpRequest<any>

    if (req.url.indexOf('assets') === 0) {
      // do not change url for assets
      apiReq = req.clone({ url: req.url })
    } else if (req.url.indexOf('https://track.taxcloud.com') === 0) {
      // do not change pardot tracking url
      apiReq = req.clone({ url: req.url })
    } else if (req.url.indexOf('https://api.taxcloud.net/1.0/') === 0) {
      // allow calling to api.taxcloud without changing anything
      apiReq = req.clone({ url: req.url })
    } else if (
      req.url === 'api/account/onetimelogin' ||
      req.url === 'api/account/ForgotPassword' ||
      req.url === 'api/post-wayfair-states' ||
      req.url === 'api/account/tos' ||
      req.url === 'api/tics' ||
      req.url === 'api/account/loginoauth' ||
      req.url === 'api/account/registeroauth' ||
      req.url === 'api/marketplace/EmailOwnershipAuthorization' ||
      req.url === 'api/account/confirmemail' ||
      req.url === 'api/gemini-relayer/upload-app-route-event-unauthorized' ||
      req.url.indexOf('api/rate-finder-details') === 0 ||
      req.url.indexOf('api/account/login') !== -1 ||
      req.url.indexOf('api/account/register3') !== -1 ||
      req.url.indexOf('onetimeloginmarketplace') !== -1
    ) {
      // does not need a token
      apiReq = req.clone({ url: `${environment.baseUrl}/${req.url}` })
    } else if (req.url.indexOf('cms') === 0) {
      apiReq = req.clone({
        url: `https://api.buttercms.com/v2/${req.url.substring(4)}`,
        setParams: {
          auth_token: environment.cms_read_key,
        },
      })
    } else if (req.url.indexOf('cdn') === 0) {
      apiReq = req.clone({
        url: `https://cdn.buttercms.com/${req.url.substring(4)}`,
      })
    } else {
      // everything else needs a token to succeed
      if (!token) {
        throw new Error(`Missing token: ${req.url}`)
      }
      // if hitting another url or service
      const urls = [
        environment.directFilingUrl,
        environment.billingServiceUrl,
        environment.integrationsUrl,
        environment.publicApiBaseUrl,
      ]
      if (urls.some((url) => url && req.url.includes(url))) {
        apiReq = req.clone({
          url: `${req.url}`,
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        })
      } else {
        apiReq = req.clone({
          url: `${environment.baseUrl}/${req.url}`,
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        })
      }
    }

    // Send gemini cookie id to all core api requests
    if (apiReq.url.includes(environment.baseUrl)) {
      const geminiCookie = this.geminiService.getGeminiCookie()
      if (geminiCookie) {
        apiReq = apiReq.clone({
          params: apiReq.params.set('geminiCookieId', geminiCookie),
        })
      }
    }

    return apiReq
  }
}
