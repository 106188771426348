<!-- <div
    class="padding_sm margin_t_md"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayout.gt-xs="row wrap"
    fxLayoutAlign.gt-xs="space-around stretch"
    fxLayoutGap="1rem"
    [style.backgroundColor]= "backgroundColorDark">

    <div 
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1rem">

        <div class="mat-display-1" [style.color]="white">Company</div>
        <div [tcLink]="darkTheme" [routerLink]="'/contact'">Contact</div>
        <div [tcLink]="darkTheme" [routerLink]="'/about'">About</div>
        <div [tcLink]="darkTheme" [routerLink]="'/press'">Press</div>
        <div [tcLink]="darkTheme" [routerLink]="'/brand'">Brand</div>
        
    </div>

    <div 
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1rem">

        <div class="mat-display-1" [style.color]="white">Integrations</div>
        <div *ngFor="let integration of integrations">
            <div [tcLink]="darkTheme" [routerLink]="'/go/integrations/'+integration.value.toString()">{{integration.label}}</div>
        </div>
        
    </div>

    <div 
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1rem">

        <div class="mat-display-1" [style.color]="white">Resources</div>
        <div><a [tcLink]="darkTheme" [routerLink]="'/developer'" target="_blank" rel="noopener">Developer Center</a></div>
        <div [tcLink]="darkTheme" [routerLink]="'/tic'">TICs</div>
        <div [tcLink]="darkTheme" [routerLink]="'/support'">Support</div>
        <div [tcLink]="darkTheme" [routerLink]="'/state-guides'">State Guides</div>
        <div [tcLink]="darkTheme" [routerLink]="'/blog'">News Blog</div>
        <div [tcLink]="darkTheme" (click)="legacySignIn()">Legacy Site</div>
    </div>

    <div 
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="1rem">

        <div class="mat-display-1" [style.color]="white">Product</div>
        <div [tcLink]="darkTheme" [routerLink]="'/pricing/enhanced-services'">Enhanced Services</div>
        <div [tcLink]="darkTheme" [routerLink]="'/pricing/core-services'">Selective Compliance</div>
        <div [tcLink]="darkTheme" [routerLink]="'/pricing'">Pricing</div>

    </div>
 
</div> -->

<div
  class="padding_sm"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutAlign.gt-sm="start center"
  fxLayoutGap="1rem"
  [style.backgroundColor]="backgroundColorDarkest"
>
  <!-- <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-md="center center"
        fxLayoutGap="1rem" >
        <div aria-label="Copyright, terms of use and privacy policy"></div>
        <div [tcLink]="darkTheme" [routerLink]="'/tos/'">Terms of Service</div>
        <div [tcLink]="darkTheme" [routerLink]="'/privacy'">Privacy Policy</div>
        <div [tcLink]="darkTheme" [routerLink]="'/security'">Security Policy</div>
    </div> -->

  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-md="center center"
    fxLayoutGap="1rem"
  >
    <div [style.color]="lightColor">© The Federal Tax Authority, LLC d/b/a TaxCloud.</div>
    <div [style.color]="lightColor">
      TaxCloud® is a Registered Trademark of The Federal Tax Authority, LLC
    </div>
  </div>
</div>
