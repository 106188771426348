import moment from 'moment'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import {
  CartTypeEnum,
  MerchantStoreStatusEnum,
  StoreAutoImportEnum,
  StoreTypeEnum,
} from 'src/app/enums/merchant-store.enums'
import { validateDate } from 'src/app/utilities/date.utilities'

import { IAmazonForm } from '../models/iamazon-form'
import {
  IApiAddEditStore,
  IApiCart,
  IApiReturnEditStore,
  IApiStore,
  IApiStoreUpload,
} from '../models/iapi-store'
import { IMerchantStoreForm } from '../models/imerchant-store-form.model'
import { IMerchantStoreCart } from '../models/imerchant-store-type.model'
import { IMerchantStoreUpload } from '../models/imerchant-store-upload.model'
import { IBasicStore, IMerchantStore } from '../models/imerchant-store.model'
import { IShopifyForm } from '../models/ishopify-form'

export class MerchantStoreAdapter {
  public mapStoreToIAddEditStore(store: IMerchantStore): IApiAddEditStore {
    const marketplaceID = ''
    const marketplaceAuthToken = ''
    const marketplaceType = store.cart.type
    let marketplaceUploadType = StoreAutoImportEnum.False
    const marketplaceSellerID = ''

    switch (store.type) {
      // case StoreTypeEnum.Amazon:
      //     const amazon = store.detail as IAmazonForm;
      //     marketplaceType = store.cart.type;
      //     marketplaceID = amazon.marketplaceId.trim();
      //     marketplaceAuthToken = amazon.marketplaceAuthToken.trim();
      //     marketplaceSellerID = amazon.marketplaceSellerId.trim();
      //     break;
      // case StoreTypeEnum.Shopify:
      //   const shopify = store.detail as IShopifyForm;
      //   marketplaceType = store.cart.type;
      //   marketplaceID = `${shopify.storeId.trim()}.myshopify.com`;
      //   marketplaceAuthToken = shopify.authToken || '';
      //   marketplaceUploadType = store.autoImport
      //                           ? StoreAutoImportEnum.True
      //                           : StoreAutoImportEnum.False;
      //   break;

      case StoreTypeEnum.Linked:
        marketplaceUploadType = store.autoImport
          ? StoreAutoImportEnum.True
          : StoreAutoImportEnum.False
        break
    }

    const mapped: IApiAddEditStore = {
      cartID: store.cart.type.toString(),
      defaultTIC: store.defaultTic ? store.defaultTic.toString() : '0',
      friendlyName: store.name,
      marketplaceType: marketplaceType.toString(),
      marketplaceUploadType: marketplaceUploadType.toString(),
      url: store.url,
      ID: store.id,
      marketplaceID,
      marketplaceSellerID,
    }

    if (marketplaceID) {
      mapped.marketplaceID = marketplaceID
    }

    if (marketplaceSellerID) {
      mapped.marketplaceSellerID = marketplaceSellerID
    }

    if (marketplaceAuthToken) {
      mapped.marketplaceAuthToken = marketplaceAuthToken
    }

    return mapped
  }

  public mapFormToApiAddEditStore(store: IMerchantStoreForm, id?: string): IApiAddEditStore {
    const marketplaceAuthToken = ''
    const marketplaceID = ''
    const marketplaceType = 0
    const marketplaceUploadType = StoreAutoImportEnum.False
    const marketplaceSellerID = ''

    switch (
      store.type
      // case StoreTypeEnum.Amazon:
      //   const amazon = store.detail as IAmazonForm;
      //   marketplaceType = store.cart.type;
      //   marketplaceAuthToken = amazon.marketplaceAuthToken.trim();
      //   marketplaceID = amazon.marketplaceId.trim();
      //   marketplaceSellerID = amazon.marketplaceSellerId.trim();
      //   break;
      // case StoreTypeEnum.Shopify:
      //   const shopify = store.detail as IShopifyForm;
      //   marketplaceType = store.cart.type;
      //   marketplaceID = `${shopify.storeId.trim()}.myshopify.com`;
      //   marketplaceAuthToken = shopify.authToken || '';
      //   marketplaceUploadType = shopify.autoImport
      //                           ? StoreAutoImportEnum.True
      //                           : StoreAutoImportEnum.False;
      //   break;
    ) {
    }

    const addStore: IApiAddEditStore = {
      cartID: store.cart.type.toString(),
      defaultTIC: store.defaultTic.toString(),
      friendlyName: store.name,
      marketplaceType: marketplaceType.toString(),
      marketplaceUploadType: marketplaceUploadType.toString(),
      url: store.url,
    }

    if (id) {
      addStore.ID = id.toString()
    }

    if (marketplaceAuthToken) {
      addStore.marketplaceAuthToken = marketplaceAuthToken
    }

    if (marketplaceID) {
      addStore.marketplaceID = marketplaceID
    }

    if (marketplaceSellerID) {
      addStore.marketplaceSellerID = marketplaceSellerID
    }
    return addStore
  }

  public mapStoreUploadApiToStoreUpload(upload: IApiStoreUpload): IMerchantStoreUpload {
    const update = {
      message: upload.comments,
      id: upload.id,
      merchantId: upload.merchantid,
      periodEnd: moment(upload.periodend, DateFormatEnum.Day).format(),
      periodStart: moment(upload.periodstart, DateFormatEnum.Day).format(),
      uploadDate: moment(upload.rundate, DateFormatEnum.MonthDayYear).format(),
      success: upload.success === 0 ? false : true,
      urlId: upload.urlid,
    }
    return update
  }

  public mapStoreApiToIMerchantStore(storeApi: IApiStore): IMerchantStore {
    const basic: IBasicStore = this.mapApiToBasicStore(storeApi)
    const type: StoreTypeEnum = this.getStoreType(basic.cart)
    // const [detail, errorMessage] = this.getDetails(storeApi);

    // const store: IMerchantStore = {...basic, type, detail, errorMessage };
    const store: IMerchantStore = { ...basic, type }
    return store
  }

  public getStoreType(cart: IMerchantStoreCart) {
    if (!cart) {
      return StoreTypeEnum.Default
    }

    // if(cart === null) {
    //   return StoreTypeEnum.Default
    // }
    if (cart?.type === CartTypeEnum.Amazon) {
      return StoreTypeEnum.Amazon
    }

    if (cart?.type === CartTypeEnum.Shopify) {
      return StoreTypeEnum.Shopify
    }

    if (cart?.isLinkable) {
      return StoreTypeEnum.Linked
    }

    return StoreTypeEnum.Default
  }

  public mapCartApiToIMerchantCart(cartApi: IApiCart): IMerchantStoreCart {
    const cartID = parseInt(cartApi.cartID, 10)
    const cart: IMerchantStoreCart = {
      isLinkable: false,
      linkMessage: null,
      type: cartID,
      name: cartApi.name,
      logo: '',
      color: '',
    }

    switch (cartID) {
      case CartTypeEnum.Amazon: {
        cart.name = 'Amazon'
        cart.logo = 'taxcloud:amazon'
        cart.color = '#ff9900'
        cart.logoUrl = '/assets/images/platforms/logo-amazon.svg'
        break
      }
      case CartTypeEnum.EBay: {
        cart.name = 'Ebay'
        cart.logo = 'taxcloud:full-ebay'
        cart.color = '#fff'
        cart.isLinkable = true
        break
      }
      case CartTypeEnum.QuickBooks: {
        cart.name = 'QuickBooks'
        cart.logo = 'taxcloud:full-qb'
        cart.color = '#fff'
        cart.isLinkable = true
        cart.logoUrl = '/assets/images/platforms/logo-quickbooks.svg'
        break
      }
      case CartTypeEnum.WooCommerce:
      case CartTypeEnum.WooTax: {
        cart.name = 'Woo Commerce'
        cart.logo = 'taxcloud:full-woocommerce'
        cart.logoUrl = '/assets/images/platforms/logo-woocommerce.svg'
        cart.color = '#fff'
        break
      }
      case CartTypeEnum.Bonanza: {
        cart.name = 'Bonanza'
        cart.logo = 'taxcloud:full-bonanza'
        cart.color = '#fff'
        cart.isLinkable = true
        break
      }
      case CartTypeEnum.Etsy: {
        cart.name = 'Etsy'
        cart.logo = 'taxcloud:full-etsy'
        cart.color = '#fff'
        cart.isLinkable = true
        cart.linkMessage = `The term \'Etsy\' is a trademark of Etsy, Inc. This application uses the
           Etsy API but is not endorsed or certified by Etsy, Inc.`
        break
      }
      case CartTypeEnum.Jet: {
        cart.name = 'Jet'
        cart.logo = 'taxcloud:full-jet'
        cart.color = '#fff'
        break
      }
      case CartTypeEnum.NewEgg: {
        cart.name = 'New Egg'
        cart.logo = 'taxcloud:full-newegg'
        cart.color = '#757575'
        break
      }
      case CartTypeEnum.Odoo: {
        cart.name = 'Odoo'
        cart.logo = 'taxcloud:full-odoo'
        cart.color = '#fff'
        cart.logoUrl = '/assets/images/platforms/logo-odoo.svg'
        break
      }
      case CartTypeEnum.Rakuten: {
        cart.name = 'Rakuten'
        cart.logo = 'taxcloud:full-rakuten'
        cart.color = '#fff'
        break
      }
      case CartTypeEnum.SquareUp: {
        cart.isLinkable = true
        break
      }
      case CartTypeEnum.Shopify: {
        cart.name = 'Shopify'
        cart.logo = 'taxcloud:full-shopify'
        cart.color = '#fff'
        cart.isLinkable = true
        cart.logoUrl = '/assets/images/platforms/logo-shopify.svg'
        break
      }
      case CartTypeEnum.Stripe: {
        cart.name = 'Stripe'
        cart.logo = 'taxcloud:full-stripe'
        cart.color = '#fff'
        cart.isLinkable = true
        break
      }
      case CartTypeEnum.Walmart: {
        cart.name = 'Walmart'
        cart.logo = 'taxcloud:full-walmart'
        cart.color = '#fff'
        break
      }
      case CartTypeEnum.Exactor: {
        cart.name = 'Extractor'
        cart.logo = 'taxcloud:exactor'
        cart.color = '#fff'
        break
      }
      case CartTypeEnum.BigCommerce: {
        cart.name = 'BigCommerce'
        cart.logo = 'icons:shopping-cart'
        cart.color = '#0056D7'
        cart.logoUrl = '/assets/images/platforms/logo-bc.svg'
      }
      default: {
        cart.logo = 'icons:shopping-cart'
        cart.color = '#0056D7'
        if (!cart.name) {
          cart.name = 'Unknown'
        }
      }
    }
    return cart
  }

  public mapApiEditToApiStore(edit: IApiReturnEditStore): IApiStore {
    return {
      apiKey: edit.APIKey,
      apiLoginID: edit.APILoginID,
      cartID: edit.CartID,
      cartName: edit.CartName,
      defaultSKU: edit.DefaultSKU,
      friendlyName: edit.FriendlyName,
      disabledTimestamp: '',
      goLiveTimestamp: edit.GoLiveTimestamp,
      id: edit.ID,
      isPrimary: edit.IsPrimary,
      marketplaceAuthToken: edit.MarketplaceAuthToken,
      marketplaceID: edit.MarketplaceID,
      marketplaceSellerID: edit.MarketplaceSellerID,
      marketplaceType: edit.MarketplaceType,
      marketplaceUploadType: edit.MarketplaceUploadType,
      maxCartSize: edit.MaxCartSize,
      merchantID: edit.MerchantID,
      url: edit.URL,
      isLive: edit.isLive,
    }
  }

  private mapApiToBasicStore(api: IApiStore): IBasicStore {
    let passAsCartId = api.cartID.toString()

    if (passAsCartId === '0' && api.marketplaceType) {
      passAsCartId = api.marketplaceType.toString()
    }

    const cart: IMerchantStoreCart = this.mapCartApiToIMerchantCart({
      cartID: passAsCartId,
      name: api.cartName,
    })
    const disabledDate = validateDate(api.disabledTimestamp)
    const liveDate = validateDate(api.goLiveTimestamp)

    return {
      api: {
        key: api.apiKey,
        loginId: api.apiLoginID,
      },
      autoImport: api.marketplaceUploadType === StoreAutoImportEnum.True,
      cart,
      defaultTic: api.defaultSKU,
      disabledDate,
      isDisabled: !!disabledDate,
      id: api.id ? api.id.toString() : '',
      liveDate,
      isLive: !!liveDate,
      isPrimary: api.isPrimary,
      maxCartSize: api.maxCartSize,
      name: api.friendlyName,
      isLinked: this.isLinked(api),
      status: this.getStatus(!!disabledDate, !!liveDate),
      url: api.url,
    }
  }

  private getStatus(disabled, live): MerchantStoreStatusEnum {
    if (disabled) {
      return MerchantStoreStatusEnum.Disabled
    }
    if (live) {
      return MerchantStoreStatusEnum.Live
    }
    return MerchantStoreStatusEnum.Pending
  }

  // TODO REMOVE
  // private getDetails(storeApi: IApiStore): [IAmazonForm | IShopifyForm , string]{
  // let store: IAmazonForm | IShopifyForm;
  // let errorMessage: string;

  // switch (storeApi.cartID) {
  // case CartTypeEnum.Amazon:
  //   store = {
  //     isValid: !!(storeApi.marketplaceID && storeApi.marketplaceSellerID && storeApi.marketplaceAuthToken),
  //     marketplaceId: storeApi.marketplaceID,
  //     marketplaceSellerId: storeApi.marketplaceSellerID,
  //     marketplaceAuthToken: storeApi.marketplaceAuthToken,
  //   };
  //   if  (!store.isValid) {
  //     errorMessage = 'Store is missing data';
  //   }
  //   break;
  //
  // case CartTypeEnum.Shopify:
  //   store = {
  //     isValid: !!storeApi.marketplaceID,
  //     storeId: (storeApi.marketplaceID) ? storeApi.marketplaceID.replace('.myshopify.com', '') : '',
  //     authToken: storeApi.marketplaceAuthToken,
  //     autoImport: storeApi.marketplaceUploadType === StoreAutoImportEnum.True,
  //   };
  //   if  (!store.isValid) {
  //     errorMessage = 'Store is missing data';
  //   }
  //   break;
  // }
  // return [store, errorMessage];
  // }

  private isLinked(item: IApiStore) {
    const { cartID, marketplaceAuthToken, marketplaceID, marketplaceSellerID } = item
    const store = CartTypeEnum
    let linked = false

    switch (cartID) {
      case store.Amazon:
        linked = marketplaceAuthToken && marketplaceID && marketplaceSellerID ? true : false
        break

      case store.Etsy:
      case store.Shopify:
        linked = marketplaceAuthToken && marketplaceID ? true : false
        break

      case store.Bonanza:
      case store.EBay:
      case store.QuickBooks:
      case store.Stripe:
      case store.SquareUp:
        linked = marketplaceAuthToken ? true : false
    }
    return linked
  }
}
