import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'
import { DataSourceEnum } from 'src/app/enums/dataSource.enums'

import { IMerchantStoreForm } from '../models/imerchant-store-form.model'
import { IMerchantStore } from './../models/imerchant-store.model'

export enum MerchantStoreActionTypes {
  InitStores = '[Store list page] Init stores',
  InitStoresExemptCert = '[Exempt Certificate list page] Init stores',
  UpsertStores = '[Store Effects] Upsert stores',

  RefreshStore = '[Store list page] Refresh stores',
  RefreshStoreSuccess = '[Store effects] Refresh stores',

  AddStore = '[Store Add Component] Add store',
  AddStoreSuccess = '[Store Effects] Upsert store',

  UpdateStoreLocal = '[Edit Store Page] Update local store',
  UpdateStore = '[Store Effects] Update store database',
  UpdateStoreSuccess = '[Store Effects] update store database success',

  LinkStore = '[Link Store Button] init link store',
  LinkStoreSuccess = '[Store Effects] link store success',

  TurnOnAutoImport = '[Auto Import Button] Turn on auto import',
  TurnOffAutoImport = '[Auto Import Button] Turn off auto import',

  FirstGoLive = '[Get Started Page] Take store and account live',
  GoLive = '[Store Go Live Button] Take store live',
  GoLiveSuccess = '[Marchant Store Effects] Save go-live data to store',

  DisableStore = '[Store Disable Button] Disable store',
  DisableStoreSuccess = '[Store Effect] Disable store success',

  ClearStores = '[Logout] Clear stores',
}

export class InitStores implements Action {
  readonly type = MerchantStoreActionTypes.InitStores
}

export class InitStoresExemptCert implements Action {
  readonly type = MerchantStoreActionTypes.InitStoresExemptCert
}

export class AddStore implements Action {
  readonly type = MerchantStoreActionTypes.AddStore

  constructor(
    public payload: { merchantStoreForm: IMerchantStoreForm; redirectToStorePage?: boolean }
  ) {}
}

export class AddStoreSuccess implements Action {
  readonly type = MerchantStoreActionTypes.AddStoreSuccess

  constructor(public payload: { merchantStore: IMerchantStore; redirectToStorePage?: boolean }) {}
}

export class UpsertStores implements Action {
  readonly type = MerchantStoreActionTypes.UpsertStores

  constructor(public payload: { merchantStores: IMerchantStore[]; source: DataSourceEnum }) {}
}

export class RefreshStore implements Action {
  readonly type = MerchantStoreActionTypes.RefreshStore
}

export class RefreshStoreSuccess implements Action {
  readonly type = MerchantStoreActionTypes.RefreshStoreSuccess
  constructor(public payload: { merchantStores: IMerchantStore[] }) {}
}

export class LinkStore implements Action {
  readonly type = MerchantStoreActionTypes.LinkStore

  constructor(public payload: { merchantStore: IMerchantStore }) {}
}

export class LinkStoreSuccess implements Action {
  readonly type = MerchantStoreActionTypes.LinkStoreSuccess
}

export class UpdateStoreLocal implements Action {
  readonly type = MerchantStoreActionTypes.UpdateStoreLocal

  constructor(public payload: { merchantStore: Update<IMerchantStore>; reloadPage?: boolean }) {}
}

export class UpdateStore implements Action {
  readonly type = MerchantStoreActionTypes.UpdateStore

  constructor(public payload: { id: string; reloadPage?: boolean }) {}
}

export class UpdateStoreSuccess implements Action {
  readonly type = MerchantStoreActionTypes.UpdateStoreSuccess

  constructor(public payload: { merchantStore: IMerchantStore; reloadPage?: boolean }) {}
}

export class TurnOnAutoImport implements Action {
  readonly type = MerchantStoreActionTypes.TurnOnAutoImport
  constructor(public payload: { id: string }) {}
}

export class TurnOffAutoImport implements Action {
  readonly type = MerchantStoreActionTypes.TurnOffAutoImport
  constructor(public payload: { id: string }) {}
}

export class ClearStores implements Action {
  readonly type = MerchantStoreActionTypes.ClearStores
}

export class GoLive implements Action {
  readonly type = MerchantStoreActionTypes.GoLive
  constructor(public payload: { merchantStore: Update<IMerchantStore> }) {}
}

export class FirstGoLive implements Action {
  readonly type = MerchantStoreActionTypes.FirstGoLive
  constructor(public payload: { merchantStore: Update<IMerchantStore> }) {}
}

export class GoLiveSuccess implements Action {
  readonly type = MerchantStoreActionTypes.GoLiveSuccess
}

export class DisableStore implements Action {
  readonly type = MerchantStoreActionTypes.DisableStore
  constructor(public payload: { merchantStore: Update<IMerchantStore> }) {}
}

export class DisableStoreSuccess implements Action {
  readonly type = MerchantStoreActionTypes.DisableStoreSuccess
}

export type MerchantStoreActions =
  | InitStores
  | InitStoresExemptCert
  | AddStore
  | AddStoreSuccess
  | UpsertStores
  | RefreshStore
  | RefreshStoreSuccess
  | UpdateStoreLocal
  | UpdateStore
  | UpdateStoreSuccess
  | TurnOnAutoImport
  | TurnOffAutoImport
  | ClearStores
  | FirstGoLive
  | GoLive
  | GoLiveSuccess
  | LinkStore
  | LinkStoreSuccess
  | DisableStore
  | DisableStoreSuccess
