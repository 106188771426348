import { IAPIAddExemptCertResponse } from './../models/iapi-add-exemption-certificate'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map, tap, withLatestFrom } from 'rxjs/operators'
import { IMerchantStore } from 'src/app/merchant-store/models/imerchant-store.model'
import { State as MerchantStoreState } from 'src/app/merchant-store/merchant-store.store'
import * as storeSelectors from 'src/app/merchant-store/selectors/merchant-store.selector'
import { environment } from 'src/environments/environment'

import { IApiExemptionCertificate } from '../models/iapi-exemption-certificate'
import { IDisplayExemptionCertificate } from '../models/idisplay-exemption-certificate.model'
import { IExemptionCertificate } from '../models/iexemption-certificate'
import { ExemptionCertificateAdapter } from '../utilities/exemption-certificate-adapter.utility'
import { Store } from '@ngrx/store'

@Injectable({
  providedIn: 'root',
})
export class ExemptionCertificateService {
  private adapter = new ExemptionCertificateAdapter()
  private cache: Map<string, IDisplayExemptionCertificate> = new Map()
  public workingMessage$ = new BehaviorSubject<string>('')

  constructor(
    private http: HttpClient,
    private merchantStore: Store<MerchantStoreState>
  ) {}

  public getExemptionCertificates(): Observable<IDisplayExemptionCertificate[]> {
    if (this.cache.size > 1) {
      return of(Array.from(this.cache.values()))
    }
    return this.getExemptionCertificatesFromApi()
  }

  public getExemptionCertificatesForCustomer(
    customerId: string,
    storeId: string
  ): Observable<IDisplayExemptionCertificate[]> {
    return this.getExemptionCertificates().pipe(
      map((certs) =>
        certs.filter((cert) => cert.customerId === customerId && cert.urlId.toString() === storeId)
      )
    )
  }

  public getExemptCertificate(id: string): Observable<IDisplayExemptionCertificate> {
    if (!id) {
      throw new Error('Missing certificate id')
    }
    if (this.cache.has(id)) {
      return of(this.cache.get(id))
    }

    return this.getExemptionCertificatesFromApi().pipe(
      map((certs) => certs.find((cert) => cert.id === id))
    )
  }

  public getExemptionCertificatesFromApi(): Observable<IDisplayExemptionCertificate[]> {
    this.workingMessage$.next('Getting certificates')

    return this.http.get(`api/exemptioncertificate2`).pipe(
      map((results: any) => results.result),
      withLatestFrom(this.merchantStore.select(storeSelectors.selectAll)),
      map(([result, stores]) =>
        result
          .map((cert) => {
            const certStore = stores.find((store) => Number(store.id) === cert.urlId)
            return this.adapter.mapApiCertificateToCertificate(
              cert as IApiExemptionCertificate,
              certStore as IMerchantStore
            )
          })
          .filter((cert) => cert.isDisabled === false)
      ),
      tap(() => this.cache.clear()),
      tap((certs) => certs.forEach((cert) => this.cache.set(cert.id, cert))),
      tap(() => this.workingMessage$.next(''))
    )
  }

  public addExemptionCertificate(
    certificate: Omit<IExemptionCertificate, 'id'>
  ): Observable<IAPIAddExemptCertResponse> {
    this.workingMessage$.next('Adding certificate')
    const body = this.adapter.mapCertificateToApiAddCertificate(certificate)

    return this.http
      .post(`${environment.publicApiBaseUrl}/1.0/TaxCloud/AddExemptCertificate/`, body)
      .pipe(
        map((response: IAPIAddExemptCertResponse) => {
          if (response.ResponseType !== 3) {
            throw new Error('Add Exemption Certificate Error')
          }
          return response
        }),
        tap(() => this.workingMessage$.next('')),
        tap(() => this.cache.clear())
      )
  }

  public deleteExemptionCertificate(id: string, store: IMerchantStore): Observable<any> {
    this.workingMessage$.next('Deleting certificate')
    if (!store.api) {
      throw new Error('Missing Store Keys')
    }
    const body = {
      apiKey: store.api.key,
      apiLoginID: store.api.loginId,
      certificateID: id,
    }

    return this.http
      .post(`${environment.publicApiBaseUrl}/1.0/TaxCloud/DeleteExemptCertificate`, body)
      .pipe(
        map((response: any) => {
          if (response.ResponseType !== 3) {
            throw new Error('Delete Exemption Certificate Error')
          }
          return response
        }),
        tap(() => this.workingMessage$.next('')),
        tap(() => this.cache.clear())
      )
  }

  public deleteCache(): void {
    this.cache.clear()
  }
}
