import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ShellGetStartedComponent } from 'src/app/ui/init-ui/shells/shell-get-started/shell-get-started.component'
import { ShellGoComponent } from 'src/app/ui/init-ui/shells/shell-go/shell-go.component'
import { LoginPage } from 'src/app/user/pages/login/login.page'
import { StartPage } from 'src/app/user/pages/start/start.page'

import { AccountStatusGuard } from '../services/account-status-guard'
import { ShellSetupComponent } from 'src/app/ui/init-ui/shells/shell-setup/shell-setup.component'
import { AuthGuard } from '../services/auth-guard.service'
import { ShellPublicBasicComponent } from 'src/app/public/shell-public-basic/shell-public-basic.component'
import { PlanGuard } from '../services/plan-guard.service'
import { TermsOfServiceGuard } from '../services/terms-of-service-guard'
import { AttestationGuard } from '../services/attestation-guard.service'
import { IsLeavingGuard } from '../services/leaving-guard.service'

const routes: Routes = [
  {
    path: 'sign-in',
    data: {
      title: 'Log in to TaxCloud',
      description: 'Welcome back! Please log in to view your TaxCloud account.',
      breadcrumb: 'sign in',
    },
    component: ShellPublicBasicComponent,
    children: [
      {
        path: '',
        component: LoginPage,
      },
    ],
  },
  {
    path: 'start',
    data: {
      breadcrumb: 'sign out',
    },
    component: StartPage,
  },
  {
    path: 'embedded-welcome',
    data: {
      breadcrumb: 'welcome',
    },
    loadChildren: () =>
      import('src/app/embedded-welcome/embedded-welcome.module').then(
        (m) => m.EmbeddedWelcomeModule
      ),
  },
  {
    path: 'go',
    data: {
      breadcrumb: 'go',
    },
    canActivate: [AuthGuard, AccountStatusGuard, PlanGuard],
    component: ShellGoComponent,
    loadChildren: () => import('src/app/go/go.module').then((m) => m.GoModule),
  },
  {
    path: 'get-started',
    data: {
      breadcrumb: 'get started',
    },
    component: ShellGetStartedComponent,
    loadChildren: () =>
      import('src/app/get-started/get-started.module').then((m) => m.GetStartedModule),
  },
  {
    path: 'complete-account',
    data: {
      breadcrumb: 'Setup',
    },
    component: ShellSetupComponent,
    loadChildren: () => import('src/app/setup/setup.module').then((m) => m.SetupModule),
  },
  {
    path: 'developer',
    data: {
      breadcrumb: 'developer center',
      title: 'Developer Portal',
      description: "TaxCloud's Developer Portal",
    },
    loadChildren: () =>
      import('src/app/developer-portal/developer-portal.module').then(
        (m) => m.DeveloperPortalModule
      ),
  },
]

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FirstPassRoutesModule {}
