import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

enum LayoutModes {
  Side = 'side',
  Over = 'over',
}

export interface ILayout {
  mode: LayoutModes
  disableClose: boolean
  opened: boolean
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private layout$: BehaviorSubject<ILayout> = new BehaviorSubject({
    mode: LayoutModes.Side,
    disableClose: true,
    opened: true,
  })

  constructor(private breakpointObserver: BreakpointObserver) {
    this.initializeLayout()
  }

  private initializeLayout(): void {
    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
      if (result.matches) {
        this.layout$.next({
          mode: LayoutModes.Side,
          opened: true,
          disableClose: true,
        })
      } else {
        this.layout$.next({
          mode: LayoutModes.Over,
          opened: false,
          disableClose: false,
        })
      }
    })
  }

  public toggle(): void {
    const current: ILayout = this.layout$.value
    if (current.disableClose) {
      this.layout$.next({ ...current, opened: true })
    } else {
      this.layout$.next({ ...current, opened: !current.opened })
    }
  }

  public toggleMenu(): void {
    const current: ILayout = this.layout$.value
    this.layout$.next({ ...current, opened: !current.opened })
  }

  public close(): void {
    const current: ILayout = this.layout$.value
    this.layout$.next({ ...current, opened: false })
  }

  public open(): void {
    const current: ILayout = this.layout$.value
    this.layout$.next({ ...current, opened: true })
  }

  public setLayout(opened: boolean): void {
    const current: ILayout = this.layout$.value
    this.layout$.next({ ...current, opened: opened })
  }

  public get layout() {
    return this.layout$.asObservable()
  }
}
