<div>
  <div class="flex flex-start align-items-center topnav">
    <div class="ml-4">
      <img [src]="getLogo()" class="icon" />
    </div>
  </div>
  <div class="pb-8">
    <router-outlet></router-outlet>
  </div>
  <div class="flex flex-start bottomnav fixed bottom-0 overflow-hidden">
    <tc-setup-stepper-actions
      class="w-full flex gap-5 justify-content-center align-content-center"
    ></tc-setup-stepper-actions>
  </div>
</div>
