<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="container mat-typography h-full"
  *ngIf="layout$ | async as layout"
>
  <mat-toolbar
    class="mat-elevation-z3"
    [style.marginTop.px]="0"
    [style.min-height]="(isSmallPage$ | async) ? '64px' : '94px'"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <button
        *ngIf="isSmallPage$ | async"
        mat-icon-button
        data-cy="toggle-menu"
        matTooltip="Menu Button"
        aria-label="Toggle Menu"
      >
        <mat-icon (click)="toggle()">menu</mat-icon>
      </button>
      <div class="flex align-items-center">
        <img [src]="getLogo()" class="icon" />
      </div>
    </div>

    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="(isSmallPage$ | async) === false" role="navigation">
          <button mat-button (click)="goHome()">HOME</button>

          <button mat-button (click)="goToPricing()">PRICING</button>

          <button mat-button (click)="goToSupport()">SUPPORT</button>

          <button *ngIf="isInstallable$ | async" (click)="installApp()" mat-button>INSTALL</button>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap=".5rem">
        <button mat-button data-cy="shellLoginButton" (click)="signIn()">SIGN IN</button>
        <ng-container *ngIf="isSignedIn$ | async; else register">
          <tc-sign-out-button></tc-sign-out-button>
        </ng-container>

        <ng-template #register>
          <button
            data-cy="shellRegisterButton"
            mat-button
            (click)="goToRegister()"
            tcColor="accent"
            tcColorDark
          >
            GET STARTED
          </button>
        </ng-template>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav
      class="mat-typography"
      [mode]="'over'"
      [fixedInViewport]="true"
      [fixedBottomGap]="0"
      [fixedTopGap]="64"
      [opened]="layout.opened"
      style="width: 304px"
      role="navigation"
    >
      <mat-nav-list>
        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [routerLink]="'/home'"
            aria-label="Home link"
          >
            <mat-icon class="margin_r_sm">home</mat-icon>
            <span>Home</span>
          </a>
        </div>

        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [href]="publicSiteUrl + '/pricing'"
            target="_self"
            aria-label="Pricing link"
          >
            <mat-icon class="margin_r_sm">attach_money</mat-icon>
            <span>Pricing</span>
          </a>
        </div>

        <div>
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle()"
            [href]="publicSiteUrl + '/support'"
            target="_self"
            aria-label="Support link"
          >
            <mat-icon class="margin_r_sm">support</mat-icon>
            <span>Support</span>
          </a>
        </div>
        <div *ngIf="isInstallable$ | async">
          <a
            mat-list-item
            class="padding_h_sm"
            (click)="toggle(); installApp()"
            aria-label="Install app"
          >
            <mat-icon class="margin_r_sm">add_to_home_screen</mat-icon>
            <span>Install app</span>
          </a>
        </div>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <div [style.height]="(isSmallPage$ | async) ? 'calc(100vh - 78px)' : 'calc(100vh - 151px)'">
        <tc-breadcrumb></tc-breadcrumb>
        <div style="min-height: 600px">
          <router-outlet></router-outlet>
        </div>
        <tc-footer></tc-footer>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
