<div>
  <div class="flex flex-start align-items-center topnav">
    <a class="ml-4 cursor-pointer" (click)="goHome()">
      <img [src]="getLogo()" class="icon" />
    </a>
  </div>
  <div class="pb-8">
    <router-outlet></router-outlet>
  </div>
</div>
