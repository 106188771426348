import { IAppConfig } from 'src/app/models/app-config.model'

export const environment: IAppConfig = {
  name: 'prod',
  production: true,
  cookieDomain: '.taxcloud.com',
  baseUrl: 'https://core.taxcloud.com',
  publicApiBaseUrl: 'https://api.taxcloud.net',
  billingServiceUrl: 'https://billing.taxcloud.com/api',
  integrationsUrl: 'https://integrations.taxcloud.com/api',
  publicSiteUrl: 'https://taxcloud.com',
  directFilingUrl: 'https://taxcloud-directfile-prod.azurewebsites.net',
  appInsights: {
    instrumentationKey: 'b88c18ee-2ac1-4cba-8d5e-ab77889f205f',
  },
  cms_read_key: '4a952d71620b5cba93b32cf8ae70f8111d3cf1d7',
  google_captcha_key: '6Levw7gUAAAAACB9H85Ckxp_zUqOWTGMbwZkEnG1',
  federatedAuth: {
    google: '1033617022283-n0fbatnfgsvm060ngqoc700n2ei89rqa.apps.googleusercontent.com',
    amazon: 'amzn1.application-oa2-client.b128053f683640618166b0dd9d5b3170',
  },
  gaTrackingId: 'UA-130190139-1',
  chargebee: {
    site: 'taxcloud',
    publishableKey: 'live_950vpkcurWYZmjCLkdi8s6Z7q4n8zan6n',
  },
  gocardless: {
    environment: 'live',
  },
  authorizeNet: {
    clientKey: '6G4NH5uTa5Uupavhtp2N6LfwjYV9Zr6P45NEgp5aFvaZGmTw5EgKe36G2rRjuxpf',
    apiLoginID: '8HLW3e9d22',
  },
  logging: {
    console: true,
    appShowError: false,
    appInsights: true,
    disableReduxConsole: false,
  },
  features: {
    mailFeature: true,
    invoices: true,
  },
  userflow: {
    token: 'ct_ydcazlodovcf7cbevs6wvfv744',
    checklistStartId: '53d90ba7-9519-4730-ba49-fb6e93079a29',
  },
  helpScout: {
    token: 'df407666-5fb3-457c-bba4-b9df6773bd11',
  },
}
